import Image from 'next/image'
import { useTranslation } from 'next-i18next'

import type { Theme } from '@mui/material'
import { Box, Typography, useMediaQuery } from '@mui/material'

import { ASSET_PREFIX } from '@/constants'
import { removeDoubleSlashes } from '@/utils'

import { ResetAllButton } from './ResetAllButton'

const getStaticAssetPath = (path: string) => removeDoubleSlashes(`/${ASSET_PREFIX}/${path}`)

export const NoRowsOverlay = () => {
  const { t } = useTranslation('download-center', { keyPrefix: 'mainPage' })
  const isScreenMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))
  const errorIllustrationSvgPath = getStaticAssetPath(`/svg/error-illustration.svg`)

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={3}
    >
      <Box
        p={0}
        m={0}
        gap={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography textAlign="center" variant="h2">
          {t('searchAndHitsPanel.hitsDataGrid.noResults')}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {t('searchAndHitsPanel.hitsDataGrid.noResultsOther')}
        </Typography>
      </Box>
      <Box p={0} m={0}>
        <ResetAllButton
          text={t('filtersPanel.buttons.resetAllFilters')}
          dataCy="no-results-overlay-reset-all-filters-button"
          variant="contained"
          width="auto"
        />
      </Box>
      <Image
        src={errorIllustrationSvgPath}
        alt="no results"
        width={isScreenMdUp ? 390 : 164}
        height={isScreenMdUp ? 390 : 164}
        data-cy="no-results-image"
      />
    </Box>
  )
}
